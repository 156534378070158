<!--
 * @Author: your name
 * @Date: 2021-03-11 17:33:12
 * @LastEditTime: 2021-05-14 22:28:09
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \one\src\views\tobacktwo\index.vue
-->
<template>
  <div class="dc" v-wechat-title="$route.meta.title">
    <navigation :stylemsg="stylemsg" msg="发起背调"></navigation>
    <div class="buclass">
      <button @click="menuindex = 1" :id="menuindex == 1 ? 'bu1' : 'bu3'">
        短信授权
      </button>
      <button @click="menuindex = 3" :id="menuindex == 3 ? 'bu4' : 'bu2'">
        二维码授权
      </button>
    </div>
    <div v-show="menuindex == 3" class="sq">
      <p>
        *二维码授权说明：
        <br />创建二维码→将二维码发送至候选人→候选人扫码填写个人信息、完成授权(及支付)→委托方查看背调报告；
        此模式支持“一码对多人”，适合批量型背调；
        二维码使用和管理规则详见“(底部菜单栏)二维码管理”
      </p>
    </div>
    <div v-show="menuindex != 3" class="sq">
      <p>
        *短信授权说明：
        <br />授权确认会以短信形式发送至候选人本人，请确保候选人信号畅通；候选人同意授权后，委托方即可至“(底部菜单栏)背调记录"查看报告；
      </p>
    </div>
    <div class="beimsg">
      <div class="writetipe">
        <img src="../../assets/redBar.png" />
        <p>请填写<span>被调人信息</span></p>
      </div>
      <div class="pcla">
        <p>套餐名称</p>
        <p>
          {{ taomsg.cname }}
        </p>
      </div>
      <div v-show="menuindex != 3" class="pcla">
        <p><span>*</span>手机</p>
        <p>
          <input
            v-model="obj.mobile"
            maxlength="11"
            class="inclass"
            placeholder="授权短信接受号码，本人实名手机号"
          />
        </p>
      </div>
      <div v-show="menuindex != 3" class="pcla">
        <p>身份证</p>
        <p>
          <input
            v-model="obj.idNo"
            class="inclass"
            placeholder="如委托方未填，请告知候选人必填"
          />
        </p>
      </div>
      <div v-show="menuindex != 3" class="pcla">
        <p>姓名</p>
        <p>
          <input
            v-model="obj.name"
            class="inclass"
            placeholder="委托方未填，请告知候选人必填"
          />
        </p>
      </div>
      <div v-show="menuindex == 3" class="pcla">
        <p><span>*</span>二维码名称</p>
        <p>
          <input
            v-model="obj.qr_name"
            class="inclass"
            placeholder="XX门店或XXX岗位"
          />
        </p>
      </div>

      <div v-show="menuindex == 3" class="pcla">
        <p><span>*</span>功能状态</p>
        <div class="qiyongclass" @click="show = true">
          <div>{{ okno }}</div>
          <van-icon name="arrow-down" />
        </div>
      </div>

      <div v-show="menuindex != 3 && isxueli == true" class="pcla">
        <p>学历编号</p>
        <p>
          <input
            v-model="certId"
            class="inclass"
            placeholder="如委托方未填，请告知候选人必填"
          />
        </p>
      </div>
    </div>
    <!-- <div class="addzheng">
      <div class="writetipe">
        <img src="../../assets/redBar.png" />
        <p>请填写<span>被调人信息</span></p>
      </div>
      <div class="adclaa2">
        <div class="adclaa">
          <van-icon size=".45rem" color="#FF6D20" name="add-o" />
          <div>添加证明人信息</div>
        </div>
      </div>
      <div class="ticla">如委托方未添加，请告知候选人必填</div>
    </div> -->
    <div class="proclassall" v-show="menuindex != 3 && payindex == 4">
      <div class="proclass">
        <div class="baoxian">
          <van-checkbox
            checked-color="#1677FF"
            v-model="checked"
            shape="square"
          ></van-checkbox>
          <label @click="sebao" class="labelclass"
            >购买萝卜猎手<span @click.stop="show3 = true"
              >雇佣保障方案</span
            ></label
          >
        </div>
        <p class="baoxianmoney">
          <span>¥</span><span>{{ baoseemoney }}</span>
        </p>
      </div>
      <div class="tipclass">注：若该候选人背调后3月内从贵司离职可申请理赔</div>
      <!-- <div @click="show3 = true" class="pro2cl">
        <div></div>
        <div>
          <p>方案说明</p>
          <div class="xiangxi">
            <div>详细</div>
            <van-icon name="arrow" />
          </div>
        </div>
      </div> -->
    </div>
    <div class="payjiesall">
      <div class="payclass">
        <div>选择付款方</div>
        <div>
          <div @click="payshow = true">
            {{
              payindex == 4
                ? "委托方支付"
                : payindex == 6
                ? "候选人支付"
                : "支付方式获取失败"
            }}
          </div>
          <van-icon size=".35rem" name="play" />
        </div>
        <!-- <div>
        <button @click="payindex = 6" :id="payindex == 6 ? 'mepay' : 'qipay'">
          候选人支付
        </button>
        <button @click="payindex = 4" :id="payindex == 4 ? 'mepay' : 'qipay'">
          委托方支付
        </button>
      </div> -->
      </div>
      <div class="payjies">
        选择个人付需候选人微信支付后再开始背调；选择企业付会从企业账户中进行扣除。
      </div>
    </div>

    <div class="semunu">
      <div class="writetipe selectclass">
        <img src="../../assets/redBar.png" />
        <p>选择项目</p>
        <div class="tipcl">秒出报告</div>
      </div>

      <div class="sfcl">
        <div
          @click="selectmsg(item, index)"
          v-for="(index, item) in taomsg.set_id"
          v-bind:key="item.id"
          :class="
            $route.params.id == 4
              ? newArray[item] == item
                ? 'selecl'
                : ''
              : 'selecl'
          "
        >
          <p>{{ index.name }}</p>
          <div class="jinge">
            <div>x1</div>
            <div>¥ {{ index.price }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="moneypay">
      <div @click="$refs.kefuid.showmeth()" class="kecl">
        <div><img src="../../assets/kefu3.png" /></div>
        <div>联系客服</div>
      </div>
      <div class="mjia">
        <p>合计：</p>
        <p>
          <span>¥</span><span>{{ (projectmoney + "").split(".")[0] }}</span
          >.<span>{{ (projectmoney + "").split(".")[1] }}</span>
        </p>
      </div>
      <div>
        <button @click="tobackcenter" id="fqclass">
          {{ menuindex != 3 ? "发起授权" : "创建二维码" }}
        </button>
      </div>
    </div> -->
    <div style="height: 2.1rem"></div>

    <!-- 弹出层 -->
    <van-action-sheet v-model="show" :actions="actions" @select="onSelect" />
    <!-- <van-action-sheet v-show="show" :actions="actions" @select="onSelect" /> -->
    <terms class="temsclass" v-show="show3 == true"></terms>
    <van-action-sheet
      v-model="payshow"
      :actions="payactions"
      @select="payonSelect"
    />
    <kefu ref="kefuid"></kefu>
    <bottomkefu
      @confirm="waysmeth"
      :projectmoney="projectmoney + ''"
      :msg="menuindex != 3 ? '发起授权' : '创建二维码'"
    ></bottomkefu>
    <div id="vanid">
      <van-popup v-model="waysShow">
        <div class="waysallclass">
          <div class="wayclose">
            <div></div>
            <van-icon
              @click="waysShow = false"
              color="#C0C4CC"
              size=".4rem"
              name="cross"
            />
          </div>
          <div class="ways1class">请选择候选人的授权模式</div>
          <div class="way5class">
            <div
              v-if="waySelectIndex == 2"
              @click="waySelectIndex = 2"
              class="way4class"
            >
              <div>
                <div class="way2class">
                  <img src="../../assets/tobacktwo_face.png" alt="" />
                </div>
                <div class="way3class">人脸识别</div>
                <img
                  v-show="waySelectIndex == 2"
                  class="way7class"
                  src="../../assets/tobacktwo_ok1.png"
                  alt=""
                />
                <img
                  v-show="waySelectIndex == 1"
                  class="way7class"
                  src="../../assets/tobacktwo_ok2.png"
                  alt=""
                />
              </div>
            </div>
            <div v-if="waySelectIndex == 1" class="way4class">
              <div>
                <div class="way3class_1">93%用户选择</div>
                <div class="way2class">
                  <img src="../../assets/tobacktwo_phone.png" alt="" />
                </div>
                <div class="way3class">实名手机</div>
                <img
                  v-show="waySelectIndex == 1"
                  class="way7class"
                  src="../../assets/tobacktwo_ok1.png"
                  alt=""
                />
                <img
                  v-show="waySelectIndex == 2"
                  class="way7class"
                  src="../../assets/tobacktwo_ok2.png"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div
            v-if="waySelectIndex == 1"
            @click="waySelectIndex = 2"
            class="faceorphoneclass"
          >
            切换为人脸识别方式
          </div>
          <div
            v-if="waySelectIndex == 2"
            @click="waySelectIndex = 1"
            class="faceorphoneclass"
          >
            切换为实名手机方式
          </div>
          <div class="rentip">*手机实名验证可以降低候选人抵触感</div>
        </div>
        <div @click="tobackcenter" class="way6class">下一步</div>
      </van-popup>
    </div>
  </div>
</template>
<script>
import terms from "../../views/terms/index";
import base from "../../../util/base";
import Vue from "vue";
import { Dialog, Toast } from "vant";
import kefu from "../../components/kefu/index.vue";
import bottomkefu from "../../components/bottomkefu";
export default {
  components: {
    terms,
    kefu,
    bottomkefu,
  },
  data() {
    return {
      payshow: false, //支付方式选择
      payactions: [
        { name: "委托方支付", value: "4" },
        { name: "候选人支付", value: "6" },
      ],
      protocol: "",
      msg: "发起背调授权",
      menuindex: 1, //1:短信授权 3：二维码授权
      payindex: 4, //4：委托方支付 6：候选人支付
      taomsg: "", //套餐信息
      stylemsg: "background-color: #F6F6F7;",
      obj: {
        idNo: "",
        name: "",
        qr_name: "", //二维码名称
      },
      mo: 0,
      newArray: [],
      selfBuilt: 6, //是否是自建套餐  1否 2是
      accredit: "", // 授权方式  1短信授权、2上传授权书 3、生物授权
      setId: [], // 具体接口
      url: "", //服务器地址
      show: false,
      actions: [{ name: "启用" }, { name: "禁用" }],
      okno: "启用", //启用还是禁用二维码
      status: 1, // 状态 1-开启  0-关闭
      checked: true, //是否勾选协议
      baocount: 0, //已购买的保险次数
      baoseemoney: "0.00", //保险展示价格
      baoxianlength: "",
      show3: false, //是否展示保险业务
      msg2: "条款内容",
      certId: "", //学历编号
      isxueli: false, //是否包含学历
      projectmoney: "00.00", //套餐金额
      waysShow: false,
      waySelectIndex: 1, //2:人脸识别 1:实名手机
      lock: true, //避免重复提交
    };
  },

  destroyed() {
    sessionStorage.removeItem("sub2msgMobile");
    sessionStorage.removeItem("sub2msgName");
    sessionStorage.removeItem("sub2msgId_no");
    sessionStorage.removeItem("sub2msgid");
  },
  watch: {
    payindex() {
      this.checked = false; //去除保险
    },
    menuindex(oldmsg, nowmsg) {
      if (nowmsg == "1") {
        this.payindex = 6;
        this.checked = false; //去除保险
      } else {
        this.payindex = 4;
      }
    },
    checked(oldmsg) {
      if (oldmsg) {
        this.projectmoney += Number(this.baoseemoney);
      } else {
        this.projectmoney = Number(this.taomsg.price);
      }
    },
  },
  created() {
    /**是否有手机号进入 */
    if (sessionStorage.getItem("sub2msgMobile")) {
      this.obj.mobile = sessionStorage.getItem("sub2msgMobile");
    }
    /**是否有姓名进入 */
    if (sessionStorage.getItem("sub2msgName")) {
      this.obj.name = sessionStorage.getItem("sub2msgName");
    }
    /**是否有身份证进入 */
    if (sessionStorage.getItem("sub2msgId_no")) {
      this.obj.idNo = sessionStorage.getItem("sub2msgId_no");
    }
  },
  async mounted() {
    // 查询保险次数
    this.oldmoney();

    this.url = base.url;
    // 监听是否是从创建二维码进入
    if (this.$route.params.isqrcode == "true") {
      this.menuindex = 3;
    }
    if (this.$route.params.taomsg == undefined) {
      this.$router.push("/backtotwo");
      return;
    }
    this.taomsg = this.$route.params.taomsg;

    this.projectmoney = Number(this.taomsg.price);

    /**判断是否包含学历 */
    this.ishavexueli();

    await this.allmoney();
  },

  methods: {
    /**
     * 选择支付方式
     */
    payonSelect(item) {
      this.payshow = false;
      this.payindex = item.value;
    },

    /**
     * 查询是否包含学历编号
     */
    ishavexueli() {
      for (let i = 0; i < this.taomsg.set_id.length; i++) {
        if (this.taomsg.set_id[i].api_name == "educationInfo_query") {
          this.isxueli = true;
        }
      }
    },

    fatherMethod() {
      this.show3 = false;
    },
    /**
     * 查询保险次数
     */
    selectbao() {
      var that = this;
      return new Promise(function (resolve, reject) {
        that.$http
          .post("/firm/v1/Background/buyInsuranceCount", {
            reqType: "Background",
          })
          .then((res) => {
            that.baocount = JSON.parse(res.data).data.count;
            that.baoup = JSON.parse(res.data).data.is_up;
            resolve();
          });
      });
    },

    /**
     * 最终金额计算（含保险）
     */
    async oldmoney() {
      await this.selectbao();

      if (parseInt(this.baocount) > 10) {
        this.checked = false; //取消自动勾选保险业务
        if (((this.taomsg.price / 100) * 3).toFixed(2) < 3) {
          this.baoseemoney = "3.00";
        } else {
          this.baoseemoney = Number(((this.taomsg.price / 100) * 3).toFixed(2));
        }
      } else {
        this.baoseemoney = "0.00";
      }
    },

    /**选择保险 */
    sebao() {
      this.checked = !this.checked;
    },

    onSelect(item) {
      // 默认情况下点击选项时不会自动收起
      // 可以通过 close-on-click-action 属性开启自动收起
      this.show = false;
      this.okno = item.name;
      if (this.okno == "启用") {
        this.status = 1;
      } else {
        this.status = 0;
      }
      //
    },
    selectmsg(index, item) {
      if (this.$route.params.id == 4) {
        if (this.newArray[index] == index) {
          Vue.set(this.newArray, index, null);
          this.mo -= parseInt(item.price);
          this.setId = this.setId.filter((res) => res != item.id);
        } else {
          Vue.set(this.newArray, index, index);
          this.mo += parseInt(item.price);
          this.setId.push(item.id);
        }
      }
    },
    allmoney() {
      if (this.$route.params.id == 4) {
        return;
      } else {
        this.mo = 0;
        let aaa = this.taomsg.set_id;

        this.mo = this.$route.params.taomsg.price;
        for (let i = 0; i < aaa.length; i++) {
          // this.mo += parseInt(aaa[i].price);
          this.setId.push(aaa[i].id);
        }
        return this.mo;
      }
    },

    qrback() {
      this.$toast.loading();
      this.$http
        .post("/firm/v1/Background/addNew3", {
          reqType: "Background",
          l_id: "",
          pay_type: this.payindex, //个人支付 1\2\3消耗数量   4金额 6个人支付
          num: this.projectmoney, //金额
          set_id: this.setId, //具体产品信息 数组
          accredit: this.menuindex, // 授权方式  1短信授权、2上传授权书 3、生物授权
          product_edition: this.taomsg.cname, //套餐名称
          self_built: "1", //是否是自建套餐  1否 2是
          p_id: this.taomsg.id, //商品 id
          status: this.status, // 状态 1-开启  0-关闭
          url: base.url + "/qrcode", //跳转到哪个页面
          qr_name: this.obj.qr_name, // 二维码名称
          set_meal_id: this.taomsg.id, //套餐 id
          auth_mode: this.waySelectIndex, //授权模式 1-短信二要素认证 2-人脸二要素认证
        })
        .then((res) => {
          sessionStorage.removeItem("sub2msgMobile");
          sessionStorage.removeItem("sub2msgName");
          sessionStorage.removeItem("sub2msgId_no");
          let qrid = JSON.parse(res.data).data.qr_id;
          let base = window.btoa(qrid);
          this.$router.push({
            path: "qrcode",
            query: {
              qrId: base + "",
              company: JSON.parse(res.data).data.company, //公司名称
              qrName: this.obj.qr_name,
            },
          });
        });
    },
    //选择验证方式 人脸/二要素
    waysmeth() {
      if (
        this.baoup == 0 &&
        parseInt(this.baocount) > 10 &&
        this.checked == true
      ) {
        Dialog.confirm({
          title: "提醒",
          message:
            "保险免费次数已用完，需要进行高级验证后才能勾选保险业务！请联系客服完成高级认证！",
        })
          .then(() => {
            // on confirm
          })
          .catch(() => {
            // on cancel
          });
        return;
      }
      if (this.menuindex == "3") {
        if (this.obj.qr_name == "") {
          this.$toast("请输入二维码名称");
          return;
        }
      } else {
        if (this.obj.mobile == "" || this.obj.mobile == undefined) {
          this.$toast.fail("请输入手机号");
          return;
        } else if (!/^1[3-9]\d{9}$/.test(this.obj.mobile)) {
          Dialog({ message: "请输入正确手机号" });
          return;
        }

        if (this.obj.name != "") {
          if (
            !/^[\u4e00-\u9fa5]+$/gi.test(this.obj.name) ||
            (this.obj.name + "").length < 1
          ) {
            this.$toast("请输入正确姓名");
            return;
          }
        }
        if (this.obj.idNo != "") {
          if (
            (this.obj.idNo + "").length < 16 ||
            (this.obj.idNo + "").length > 19
          ) {
            this.$toast("请输入正确身份证号码");
            return;
          }
        }
      }
      this.waysShow = true;
    },
    tobackcenter() {
      if (this.lock == true) {
        this.lock = false;
      } else {
        return;
      }
      // 二维码授权
      if (this.menuindex == "3") {
        this.qrback();
        return;
      }

      Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: "发送中，请稍后",
      });

      /**
       * 手机，身份证，姓名，套餐名称，学历编号.
       */
      this.$http
        .post("/firm/v1/Background/addNew1", {
          reqType: "Background",
          name: this.obj.name, //姓名
          id_no: this.obj.idNo, //身份证号
          mobile: this.obj.mobile, //手机号
          l_id: "",
          s_id:
            sessionStorage.getItem("sub2msgName") == this.obj.name &&
            sessionStorage.getItem("sub2msgMobile") == this.obj.mobile
              ? sessionStorage.getItem("sub2msgid")
              : "",
          pay_type: this.payindex, //个人支付 1\2\3消耗数量   4金额 6个人支付
          num: this.projectmoney, //金额
          set_id: this.setId, //具体产品信息 数组
          accredit: this.menuindex, // 授权方式  1短信授权、2上传授权书 3、生物授权
          product_edition: this.taomsg.cname, //套餐名称
          self_built: 1, //是否是自建套餐  1否 2是
          p_id: this.taomsg.id, //商品 id
          is_insurance: this.checked == true ? "1" : "0", // 是否勾选了保险业务
          set_meal_id: this.taomsg.id, //套餐 id
          certId: this.certId, //学历证书编号
          auth_mode: this.waySelectIndex, //授权模式 1-短信二要素认证 2-人脸二要素认证
        })
        .then((res) => {
          this.lock = true;
          Toast.clear();
          sessionStorage.removeItem("sub2msgMobile");
          sessionStorage.removeItem("sub2msgName");
          sessionStorage.removeItem("sub2msgId_no");
          if (JSON.parse(res.data).code != "8037") {
            if (this.menuindex == 3) {
              // this.$router.push("/qrcode");

              this.$router.push({
                name: "qrcode",
                params: {
                  msg: this.payindex == 6 ? "backcenter" : "backcenter2",
                  url: this.url,
                  phone: this.obj.mobile,
                  idNo: this.obj.idNo,
                  name: this.obj.name,
                },
              });
            } else if (this.menuindex == 1 && this.payindex == 6) {
              this.$router.push("/backsendsuccess");
            } else if (this.menuindex == 1 && this.payindex == 4) {
              this.$router.push("/backsendsuccess");
            }
          } else {
            this.$toast(JSON.parse(res.data).msg);
          }
        })
        .catch((res) => {});
    },
  },
};
</script>
<style scoped>
.faceorphoneclass {
  font-size: 0.25rem;
  font-weight: 400;
  color: #4571d0;
  line-height: 0.23rem;
  margin-top: 0.14rem;
  margin-bottom: 0.27rem;
  text-align: center;
}
.rentip {
  font-size: .25rem;
  color: #909399;
  text-align: center;
}
.way7class {
  position: absolute;
  top: -0.05rem;
  right: -0.05rem;
  width: 0.6rem;
}
.way6class {
  font-size: 0.32rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ff6d20;
  line-height: 0.48rem;
  padding: 0.22rem 0;
  text-align: center;
  border-top-color: rgb(229, 229, 229);
  border-top-style: solid;
  border-top-width: 1px;
  margin-top: 0.44rem;
}
.way5class {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.48rem;
}
.way4class {
  width: 2.34rem;
  height: 2.59rem;
  position: relative;
  background: #ffffff;
  border-radius: 0.14rem;
  border: 0.04rem solid rgba(254, 89, 3, 0.75);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.way2class {
  text-align: center;
}
.way2class img {
  height: 0.76rem;
}
.way3class {
  font-size: 0.28rem;
  font-weight: 400;
  color: #303133;
  line-height: 0.25rem;
  text-align: center;
  margin-top: 0.24rem;
}
.way3class_1 {
  font-size: 0.25rem;
  font-weight: 500;
  color: rgba(254, 89, 3, 0.75);
  line-height: 0.25rem;
  text-align: center;
  margin-bottom: 0.3rem;
}
.wayclose {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dc >>> #vanid .van-popup {
  width: 80%;
  overflow: hidden;
  box-shadow: 0rem 0.08rem 0.24rem 0rem rgba(0, 0, 0, 0.24);
  border-radius: 0.32rem;
}
.ways1class {
  font-size: 0.32rem;
  font-weight: 500;
  color: #303133;
  line-height: 0.28rem;
  white-space: nowrap;
  text-align: center;
}
.waysallclass {
  padding: 0.34rem;
  padding-bottom: 0;
}
.tipcl {
  font-size: 0.24rem;
  font-weight: 400;
  color: #909399;
  line-height: 0.33rem;
  margin-left: 0.1rem;
  padding-top: 0.1rem;
}
.semunu {
  background-color: #ffffff;
  padding: 0.44rem 0;
}
.kecl > div:first-child {
  text-align: center;
}
.kecl > div:nth-child(2) {
  text-align: center;
  color: #003aff;
  margin-top: 0.03rem;
}
.kecl img {
  width: 0.49rem;
  height: 0.48rem;
}
.payjiesall {
  background-color: #ffffff;
  padding: 0.34rem 0.3rem;
  margin: 0.26rem 0;
}
.payjies {
  font-size: 0.24rem;
  font-weight: 400;
  color: #c0c4cc;
  line-height: 0.33rem;
  margin-top: 0.24rem;
}
.dc >>> .van-checkbox {
  border-radius: 0.04rem;
}
.ticla {
  text-align: center;
  font-size: 0.24rem;
  font-weight: 400;
  color: #c0c4cc;
  line-height: 0.44rem;
  margin-top: 0.14rem;
}
.adclaa {
  display: flex;
  align-items: center;
  font-size: 0.28rem;
  font-weight: 500;
  color: #ff6d20;
  line-height: 0.48rem;
  padding: 0.2rem 0.68rem;
  border-radius: 0.42rem;
  border: 0.02rem solid #ff6d20;
}
.adclaa > :first-child {
  margin-right: 0.18rem;
}
.adclaa2 {
  display: flex;
  justify-content: center;
}
.addzheng {
  background-color: #ffffff;
  margin: 0.26rem 0;
  padding-top: 0.42rem;
  padding-bottom: 0.58rem;
}
.beimsg {
  background-color: #ffffff;
  padding: 0.42rem 0;
}
.temsclass {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 1000;
  background-color: #ffffff;
  overflow: auto;
}
input {
  min-width: 5rem;
}
.qiyongclass > div:first-child {
  margin-right: 0.05rem;
}
.qiyongclass {
  display: flex;
  align-items: center;
}
input {
  text-align: right;
}
.xiangxi {
  display: flex;
  align-items: center;
  margin-left: 0.2rem;
}
.baoxian {
  display: flex;
  align-items: center;
}
.baoxianmoney {
  font-size: 0.36rem;
  font-weight: 500;
  color: #303133;
  line-height: 0.33rem;
}
.baoxianmoney > :first-child {
  font-size: 0.24rem;
}
.labelclass {
  font-size: 0.28rem;
  font-weight: 500;
  color: #616b80;
  line-height: 0.33rem;
  margin-left: 0.14rem;
}
.labelclass span {
  color: #ff6d20;
}
.inclass {
  border: none;
}

.tipclass {
  font-size: 0.24rem;
  font-weight: 400;
  color: #c0c4cc;
  line-height: 0.24rem;
  margin-top: 0.18rem;
}
.buclass {
  display: flex;
  justify-content: center;
  font-size: 0.32rem;
  line-height: 0.48rem;
  overflow: hidden;
  /* margin-top: 0.24rem; */
  margin-bottom: 0.23rem;
  font-weight: 500;
}
#bu1,
#bu2,
#bu3,
#bu4 {
  border: none;
  width: 50%;
  padding: 0.24rem 0;
}
#bu1 {
  background: linear-gradient(90deg, #ff5f18 0%, #ff8d35 100%);
  color: #ffffff;
}
#bu2 {
  background-color: #ffffff;
  color: #ff6d20;
}
#bu3 {
  background-color: #ffffff;
  color: #ff6d20;
}
#bu4 {
  background: linear-gradient(90deg, #ff5f18 0%, #ff8d35 100%);
  color: #ffffff;
}
.sq {
  margin: 0.34rem 0.42rem;
  font-size: 0.24rem;
  line-height: 0.33rem;
  color: #606266;
}
.writetipe {
  display: flex;
  align-items: center;
  margin-left: 0.32rem;
  margin-bottom: 0.54rem;
  font-size: 0.36rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #303133;
  line-height: 0.62rem;
}
.writetipe span {
  color: #ff6d20;
}
.writetipe img {
  width: 0.1rem;
  height: 0.44rem;
  margin-right: 0.2rem;
}
.pcla {
  display: flex;
  justify-content: space-between;
  margin: 0 0.32rem;
  margin-top: 0.52rem;
  align-items: center;
}
.pcla > p:first-child {
  font-size: 0.28rem;
  font-weight: 500;
  color: #616b80;
  line-height: 0.4rem;
}
.pcla > p:nth-child(2) {
  font-size: 0.28rem;
  font-weight: 500;
  color: #303133;
  line-height: 0.44rem;
  width: 5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: right;
}
.pcla span {
  color: #e14549;
}

.sp {
  background-color: rgba(245, 86, 19, 0.19);
  margin: 0.23rem 0;
  padding: 0.2rem 0.43rem;
  font-size: 0.24rem;
  color: #f55613;
}

.payclass {
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  font-size: 0.28rem;
  font-weight: 500;
  color: #616b80;
  line-height: 0.4rem;
}
.payclass > :nth-child(2) {
  display: flex;
  align-items: center;
  font-size: 0.28rem;
  font-weight: 400;
  color: #303133;
  line-height: 0.4rem;
}
.payclass > :nth-child(2) > :first-child {
  margin-right: 0.24rem;
}
#mepay {
  border: none;
  color: #ffffff;
  background-color: #1677ff;
  font-size: 0.24rem;
  padding: 0.11rem 0.51rem;
  margin: 0 0.16rem;
  border: 1px solid #1677ff;
}
#qipay {
  border: none;
  border: 1px solid #1677ff;
  background-color: #ffffff;
  color: #1677ff;

  font-size: 0.24rem;
  padding: 0.11rem 0.51rem;
}
.selectclass {
  margin-right: 0.32rem;
  padding-bottom: 0.12rem;
  border-bottom-color: rgba(192, 196, 204, 0.28);
  border-bottom-width: 1px;
  border-bottom-style: solid;
  margin-bottom: 0.18rem;
}
.selectclass + p {
  color: #616b80;
  font-size: 0.3rem;
  margin-bottom: 0.29rem;
  font-weight: bold;
  margin-left: 0.33rem;
}
.sfcl .selecl {
  font-size: 0.28rem;
  font-weight: 500;
  color: #606266;
  line-height: 0.62rem;
  margin: 0 0.33rem;
  margin-bottom: 0.28rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.moneypay {
  padding: 0.22rem 0.3rem;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 -0.04rem 0.08rem #f5f7fa;
}
.moneypay .mjia {
  display: flex;
  align-items: flex-end;
  margin-left: 0.3rem;
}
.moneypay .mjia p {
  color: #ff5a47;
}
.moneypay .mjia p:first-child {
  font-size: 0.28rem;
  font-weight: 500;
  color: #303133;
  line-height: 0.44rem;
}
.moneypay .mjia p:nth-child(2) {
  font-size: 0.36rem;
  font-weight: 500;
  color: #ff5a47;
  line-height: 0.44rem;
}
.moneypay .mjia p:nth-child(2) span:nth-child(2) {
  font-size: 0.48rem;
}
.moneypay {
  position: fixed;
  bottom: 0;
  width: 100%;
  white-space: nowrap;
}
#fqclass {
  margin-right: 0.67rem;
  padding: 0.3rem 0.8rem;
  border: none;
  background: linear-gradient(90deg, #ff5f18 0%, #ff8d35 100%);
  border-radius: 0.54rem;
  font-size: 0.36rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 0.5rem;
}
.proclass {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.24rem;
  margin-bottom: 0.12rem;
}
.pro2cl {
  display: flex;
  color: #616b80;
  justify-content: space-between;
  align-items: center;
  padding-left: 0.6rem;
}
.pro2cl > p {
  font-size: 0.24rem;
}
.pro2cl > div p {
  font-size: 0.24rem;
}
.pro2cl div {
  display: flex;
  align-items: center;
}
.proclassall {
  font-weight: 500;
  background-color: #ffffff;
  margin: 0.26rem 0;
  padding: 0.26rem 0.34rem;
}
.proclass p span:first-child {
  font-size: 0.28rem;
  font-weight: 500;
  margin-right: 0.1rem;
}
.proclass p span:nth-child(2) {
  font-size: 0.36rem;
  font-weight: 500;
}
.proclass div:first-child label {
  font-weight: 500;
}
.proclass div:first-child input {
  margin-right: 0.2rem;
}
.jinge {
  display: flex;
  align-items: flex-end;
  font-size: 0.28rem;
  font-weight: 500;
  color: #303133;
  line-height: 0.62rem;
}
.jinge > :first-child {
  margin-right: 0.5rem;
}
.jinge > :nth-child(2) {
  min-width: 1.9rem;
  text-align: right;
}
input::placeholder {
  color: #c0c4cc;
  font-weight: 400;
}
</style>