<template>
  <div>
    <div class="moneypay">
      <div @click="$refs.kefuid.showmeth()" class="kecl">
        <div><img src="../../assets/kefu3.png" /></div>
        <div>联系客服</div>
      </div>
      <div class="allm">
        <div class="mjia">
          <p>合计：</p>
          <p>
            <span>¥</span><span>{{ (projectmoney + "").split(".")[0] }}</span
            >.<span>{{
              ((projectmoney + "").split(".")[1]+'').length == 1
                ? (projectmoney + "").split(".")[1] + "0"
                : ((projectmoney + "").split(".")[1]+'').length == 0
                ? (projectmoney + "").split(".")[1] + "00"
                : ((projectmoney + "").split(".")[1]+'').length == 2
                ? (projectmoney + "").split(".")[1]
                : "00"
            }}</span>
          </p>
        </div>
        <button :style="stylemsg" @click="tobackcenter" id="fqclass">
          {{ msg }}
        </button>
      </div>
    </div>
    <kefu ref="kefuid"></kefu>
  </div>
</template>
<script>
import kefu from "../kefu/index.vue";
export default {
  components: {
    kefu,
  },
  props: {
    projectmoney: {
      type: String,
      value: "0.00",
    },
    msg: String,
    stylemsg: String,
  },
  methods: {
    tobackcenter() {
      this.$emit("confirm");
    },
  },
};
</script>
<style scoped>
.allm {
  display: flex;
  align-items: center;
}
#fqclass {
  margin-right: 0.67rem;
  padding: 0.2rem 0.7rem;
  border: none;
  background: linear-gradient(90deg, #ff5f18 0%, #ff8d35 100%);
  border-radius: 0.54rem;
  font-size: 0.36rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 0.5rem;
}
.kecl > div:first-child {
  text-align: center;
}
.kecl > div:nth-child(2) {
  text-align: center;
  color: #003aff;
  margin-top: 0.03rem;
}
.kecl img {
  width: 0.49rem;
  height: 0.48rem;
}
.moneypay {
  padding: 0.22rem 0.3rem;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 -0.04rem 0.08rem #f5f7fa;
  z-index: 1000;
}
.moneypay .mjia {
  display: flex;
  align-items: flex-end;
  margin-right: 0.12rem;
}
.moneypay .mjia p {
  color: #ff5a47;
}
.moneypay .mjia p:first-child {
  font-size: 0.28rem;
  font-weight: 500;
  color: #303133;
  line-height: 0.44rem;
}
.moneypay .mjia p:nth-child(2) {
  font-size: 0.36rem;
  font-weight: 500;
  color: #ff5a47;
  line-height: 0.44rem;
}
.moneypay .mjia p:nth-child(2) span:nth-child(2) {
  font-size: 0.48rem;
}
.moneypay {
  position: fixed;
  bottom: 0;
  width: 100%;
  white-space: nowrap;
}
</style>
